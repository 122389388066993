
import { defineComponent, PropType } from "vue";
import _ from "lodash";
import { OfferView } from "src/api";

const formatterWithoutCurrency = new Intl.NumberFormat("sv-SE", {
  currency: "SEK"
});

export default defineComponent({
  props: {
    offer: { type: Object as PropType<OfferView>, required: true }
  },
  data: function () {
    return {
      expandedOffers: new Array<number>(),
      headerExpanded: false,
      headerExpandDone: false,
      headerExpandTreatment: false,
      headerExpandDescription: false,
      isLoaded: false
    };
  },
  methods: {
    getPaybackDurationText(offer: OfferView) {
      if (offer?.repaymentPeriod === undefined) return;
      if (offer.bank?.webDurationInMonths)
        return offer.repaymentPeriod + " månader";
      // TODO

      const repaymentPeriod = offer.repaymentPeriod;
      if (!repaymentPeriod) return "";
      if (
        (offer.bank?.name === "Collector" ||
          offer.bank?.name === "Spring Finance") &&
        repaymentPeriod < 24
      )
        return repaymentPeriod + " år";
      if (repaymentPeriod % 12) return repaymentPeriod + " månader";
      return repaymentPeriod / 12 + " år";
    },

    getTotalCost: function (offer: OfferView) {
      if (offer == null) return 0;
      if (!offer.repaymentPeriod) return 0;

      let paybackYears = 0;
      if (
        offer.bank &&
        (offer.bank.name === "Collector" ||
          offer.bank.name === "Spring Finance") &&
        offer.repaymentPeriod < 24
      )
        paybackYears = offer.repaymentPeriod;
      else paybackYears = offer.repaymentPeriod / 12;

      if (offer.bank != null && offer.bank.name == "Ikano Bank" && offer.approvedAmount != null && offer.interestRate != null) {
        var averageLoanAmount = (offer.approvedAmount + 0) / 2;
        var totalInterest = averageLoanAmount * offer.interestRate / 100 * paybackYears;
        var totalCost = offer.approvedAmount + totalInterest;
        return totalCost;

      }
      return (offer.monthlyInstallment ?? 0) * paybackYears * 12;
    },

    formatApprovedAmount(value: number) {
      if (value != null) return value.toLocaleString();
    },
    formatMonthlyInstallment(value: number) {
      if (value != null) return Math.round(value).toLocaleString();
    },
    formatDecimal: function (value: number) {
      return (Math.round(value * 100) / 100).toFixed(2).replace(".", ",");
    },
    isExpanded: function (id: number) {
      return this.expandedOffers.indexOf(id) !== -1;
    },
    expand: function (id: number) {
      const index = this.expandedOffers.indexOf(id);
      if (index === -1) this.expandedOffers.push(id);
    },
    collapse: function (id: number) {
      const index = this.expandedOffers.indexOf(id);
      if (index !== -1) this.expandedOffers.splice(index, 1);
    },
    toggle: function (id: number) {
      this.isExpanded(id) ? this.collapse(id) : this.expand(id);
    },

    format(value: number) {
      return formatterWithoutCurrency.format(value);
    },
    takeOutspaceFromNumber(number: string) {
      let value;
      if (typeof number == "number") {
        value = number;
      } else {
        const takeOutSpace = number.replace(/\s/g, "");
        value = takeOutSpace;
      }
      return value;
    },
    getArrangementFee(offer: OfferView) {
      if (offer?.bank?.offerPreset?.arrangementFee) {
        return offer.bank.offerPreset.arrangementFee
      } else {
        return offer.arrangementFee
      }
    },
    getAministrationFee(offer: OfferView) {
      if (offer?.bank?.offerPreset?.administrationFee) {
        return offer.bank.offerPreset.administrationFee
      } else {
        return offer.administrationFee
      }
    }
  }
});
