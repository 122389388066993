
import { defineComponent, PropType } from "vue";
import { api, MortgageApplicationView, MortgageOfferView } from "src/api";
import Tooltip from "src/components/Controls/Tooltip.vue";
import moment from "moment";

export default defineComponent({
  props: {
    app: { type: Object as PropType<MortgageApplicationView>, required: true },
    offer: { type: Object as PropType<MortgageOfferView>, required: true }
  },
  data() {
    return {
      headerExpandDescription: false,
      expanded: false,
      createRenewalUrlSubmitted: false
    };
  },
  components: {
    Tooltip
  },
  computed: {
    showRenewal() {
      //  don't show renewal if reached step 5 "Ladda upp köpehandlingar"
      const currentStep = this.app.mortgageFlowStatus?.findIndex(
        (it) => it.status == "Current"
      );
      if (currentStep && currentStep >= 4) return false;

      return (
        this.app.purpose == "PromissoryLetter" || this.app.purpose == "Purchase" || this.app.purpose == "Renegotiate"
      );
    },
    isPastDueDate() {
      console.log(this.daysLeftUntilDueDate <= 0);
      return this.daysLeftUntilDueDate <= 0;
    },
    daysLeftUntilDueDate() {
      return moment(this.offer.dueDate).diff(moment(), "days");
    },
    bankName() {
      return this.offer.bank?.displayName ?? "";
    },
    getOfferDueDateText() {
      if (
        this.offer.offerDueDateTextType === "SkandiaPromissoryLetter" ||
        this.offer.offerDueDateTextType === "SbabPurchase"
      )
        return "Detta är ett förslag som är baserat på de uppgifter du lämnat in. Du behöver kontakta din rådgivare för att verifiera erbjudandet och objektet innan du lägger bud.";

      if (this.offer.offerDueDateTextType === "SbabRenegotiate") {
        return "Detta är ett ränteförslag som är baserat på de uppgifter du lämnat in. Du behöver komplettera med ett amorteringsunderlag för att gå vidare.";
      }

      if (this.offer.offerDueDateTextType === "SbabPromissoryLetter")
        return "Detta är ett förslag som är baserat på de uppgifter du lämnat in. Du behöver kontakta din rådgivare för att verifiera lånelöftet. Skriv ett meddelande på Mina sidor eller ring på 020-25 26 26";
    },
  },
  methods: {
    async createRenewalUrl() {
      if (this.createRenewalUrlSubmitted) return;

      this.createRenewalUrlSubmitted = true;

      const res = await api.Mortgage.createApplicationRenewalUrl(this.offer.id);

      // redirect if we got a valid url
      if (res.data.indexOf("http") === 0) window.location.href = res.data;
    },
    formatMoney(value: number) {
      if (value != null) return Math.round(value).toLocaleString("sv");
      return "";
    },
    formatDecimal: function (value: number) {
      return (Math.round(value * 100) / 100).toFixed(2).replace(".", ",");
    },
    submitSelectOffer: function (id: number) {
      api.Mortgage.selectMortgageOffer(id);
      this.$router.push({ name: "mortgage_index" });
    },
    missingDocumentSkandia: function (status: string, name: string) {
      if (status !== "Accepted" && name === "Skandia") return true;
    },
    offerIsSelected(id: number) {
      return this.app && this.app.chosenOfferId == id;
    },
    bindingPeriod: function (bindingPeriod: string) {
      let payBackYears = "";
      if (bindingPeriod != null) {
        payBackYears = bindingPeriod.split(" ")[0];
      }
      return payBackYears;
    },
    addMinuSign(value: number) {
      if(value == null) return "";  
      if (value.toString().includes("-")) return value;
      return `-${value.toString()}`;
    },
    translateBindinPeriod(value: string) {
      if(value == null) return "";
      if (value.includes("month")) return value.replace("month", "mån");
      if (value.includes("years")) return value.replace("years", "år");
      if (value.includes("year")) return value.replace("year", "år");
    },
    update() {
      this.$emit("update");
    }
  }
});
